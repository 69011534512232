<template>
    <modal ref="modalAgregarEquipoModelo" titulo="Cargar equipo de otra oportunidad" adicional="Añadir" @adicional="agregarEquipoMOdelo">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="col-9 my-3">
                    <p class="input-label-top">Oportunidad</p>
                    <ValidationProvider v-slot="{ errors }" name="oportunidad" rules="required">
                        <el-select v-model="selectOps" placeholder="Seleccionar oportunidad" size="small" class="w-100" @change="equipoSelect">
                            <el-option v-for="(item, value) in select_projects" :key="value" :label="item.nombre" :value="item.id"/>
                        </el-select>
                        <VeeError :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-9 my-3">
                    <p class="input-label-top">Equipo modelo</p>
                    <ValidationProvider v-slot="{ errors }" name="equipo modelo" rules="required">
                        <el-select v-model="selectEquipo" placeholder="Seleccionar equipo modelo" size="small" class="w-100">
                            <el-option v-for="(item, value) in ops" :key="value" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <VeeError :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    data(){
        return {
            selectOps: '',
            selectEquipo: '',
            equipos:[]
        }
    },
    computed:{
        ...mapGetters({
            ops: 'obras/informacion/ops',
            select_projects: 'selects/selects/select_projects',
            equiposStore: 'obras/informacion/equipos',
        }),
    },
    methods: {
        ...mapActions({
            Action_get_informacion_ops: 'obras/informacion/Action_get_informacion_ops',
            Action_post_agregar_equipo: 'obras/informacion/Action_post_agregar_equipo',
            Action_get_informacion_equipos: 'obras/informacion/Action_get_informacion_equipos',
            // Action_get_select_projects: 'selects/selects/Action_get_select_projects',
        }),
        ...mapMutations({
            setProducto : 'obras/informacion/setProducto'
        }),
        async toggle(){
            this.clear()
            this.$refs.validator.reset()
            this.$refs.modalAgregarEquipoModelo.toggle()
        },
        async agregarEquipoMOdelo(){
            let valid = await this.$refs.validator.validate()
            if (valid) {
                let totalEquipos = this.equiposStore.length
                let payload = {
                    id_ops_producto : this.selectEquipo,
                    id:this.$route.params.id
                }
                this.$refs.modalAgregarEquipoModelo.toggle()
                await this.Action_post_agregar_equipo(payload)
                await this.Action_get_informacion_equipos(this.$route.params.id)
                this.seleccionarEquipo(totalEquipos)
            }
        },
        async equipoSelect(item){
            await this.Action_get_informacion_ops(item)

        },
        clear(){
            this.selectOps = []
            this.selectEquipo = []
        },
        seleccionarEquipo(totalEquipos){
            if (this.equiposStore.length > totalEquipos) {
                this.setProducto(this.equiposStore[0])
            }
        }

    }
}
</script>

<style lang="css" scoped>
</style>
